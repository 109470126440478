import React, { useEffect, useState, useRef } from "react";
import "./style.scss";
// import placeholderImage from "../../assets/image/Rectangle.png";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ePub from "epubjs";
import Loader from "assets/image/loader.gif";
import micListeningGif from "assets/image/mic_listening.gif";
import { storage as LocalStorage } from "services/config/storage";
import Navbar from "../../components/Navbar";

const StoryDetailsPage = ({ data }) => {
  const [storyData, setStoryData] = useState({});
  const [epub_rendition, updateRendition] = useState({});
  const [showStartButton, setShowStartButton] = useState(false);
  const [showTryAgainButton, setShowTryAgainButton] = useState(false);
  const [recording, setRecording] = useState(false);
  const [audioPlayer, setAudioPlayer] = useState(null);
  const [recorder, setRecorder] = useState(null);
  const [targetPhrase, setTargetPhrase] = useState("");
  const [session_id, setSessionId] = useState("");
  const [process_id, setProcessId] = useState("");
  const [stream, setStream] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showErrorDiv, setShowErrorDiv] = useState(true);
  const [showTextHighlight, setShowTextHighlight] = useState(true);
  const [showPrevButton, setShowPrevButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const [stopRecButton, setStopRecButton] = useState(false);
  const [showRecording, setShowRecording] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [count, setcount] = useState(0);
  const [showFinishButton, setShowFinishButton] = useState(false);

  console.log(showStartButton)
  var fin = 0;
  useEffect(() => {
    console.log("Data on page load:", data);
    setStoryData(data);
    loadAndDisplayEPUB();
  }, []);

  function applyStylesToImage(iframe) {
    // Access the content of the iframe
    var iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;

    // Find the image element within the iframe content
    var image = iframeDocument.querySelector(".image img");

    // Apply styles to the image
    if (image) {
      image.style.width = "200px";
      image.style.border = "2px solid black";
    }
  }

  const recordAudio = () =>
    new Promise(async (resolve) => {
      // console.log("inside rec promise",navigator.mediaDevices);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      // console.log("stream", stream);
      setStream(stream);
      const mediaRecorder = new MediaRecorder(stream);
      const audioChunks = [];
      // console.log(stream);
      // console.log(mediaRecorder);

      mediaRecorder.addEventListener("dataavailable", (event) => {
        audioChunks.push(event.data);
      });

      const start = () => {
        mediaRecorder.start();
        setRecording(true);
      };

      const stop = () =>
        new Promise((resolve) => {
          // console.log("called stop method");
          mediaRecorder.addEventListener("stop", () => {
            const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
            const audioUrl = URL.createObjectURL(audioBlob);
            const audio = new Audio(audioUrl);
            const play = () => audio.play();
            setRecording(false);
            setAudioPlayer({ audioBlob, audioUrl, play });
            resolve({ audioBlob, audioUrl, play });
          });
          console.log("media recorder state", mediaRecorder.state);
          if (mediaRecorder.state !== "inactive") {
            mediaRecorder.stop();
          }
        });

      resolve({ start, stop });
    });

  const handleRecordClick = async () => {
    const audioInstance = await recordAudio();
    audioInstance.start();
    setTimeout(() => {
      audioInstance.stop();
    }, 5000); // Adjust the duration as needed
  };

  const logObjectKeysAndValues = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        console.log(`${key}: ${obj[key]}`);
      }
    }
  };

  const loadAndDisplayEPUB = () => {
    const book = ePub(data?.url);

    let renditionWidth = 600;

    // Set default height for rendition
    let renditionHeight = 600;

    // Check if the screen width is less than 600
    if (window.innerWidth < 600) {
      renditionWidth = window.innerWidth - 100;
    }

    // Check if the screen height is less than a certain threshold (e.g., 700)
    if (window.innerHeight < 800 && window.innerHeight > 667) {
      renditionHeight = window.innerHeight - 110;
    }

    if (window.innerHeight <= 667) {
      renditionHeight = window.innerHeight - 250;
    }

    const rendition = book.renderTo("epub-file", {
      width: renditionWidth,
      height: renditionHeight,
    });

    updateRendition(rendition);

    setShowLoading(true);
    setShowStartButton(false);
    setShowNextButton(false);
    setShowPrevButton(false);

    rendition.display().then(() => {
      console.log("EPUB rendering complete");
      setShowLoading(false);
      // setShowStartButton(true);
      setShowNextButton(true);
      setShowPrevButton(false);
    });

    rendition.on("rendered", () => {
      const screenWidth = window.innerWidth;
      var iframe = document.querySelector("iframe");
      var iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;

      // Find the image element within the iframe content
      let body = iframeDocument.querySelector("body");
      let para = iframeDocument.querySelector("p");
      var image = iframeDocument.querySelector(".image img");
      var image_container = iframeDocument.querySelector(".image");

      // Check if the screen width is greater than a certain threshold (e.g., 768px)
      if (screenWidth > 768) {
        // Apply styles to the image
        if (image) {
          image.style.width = "300px";
          image.style.height = "300px";
        }
        if (body) {
          body.style.display = "flex";
          body.style.flexDirection = "column";
          body.style.justifyContent = "center";
          // body.style.gap = "8px";
          body.style.alignItems = "center";
        }
      } else {
        if (image_container) {
          image_container.style.width = "200px";
          image_container.style.height = "200px";
        }
        if (image) {
          image.style.width = "100%";
          // image_container.style.height = "300px";
        }

        if(body){
          body.style.width = "fit-content";
          body.style.display="flex"
          body.style.flexDirection="column"
          body.style.alignItems="center"
        }
        if(para){
          para.style.width="100%"
        }
      }
    });

    const modalContent = document.querySelector(".modal-content");
    modalContent.classList.add("epub-content");
  };
  const prevPage = async () => {
    if (fin == count) {
      setShowFinishButton(false);
    }
    setcount(count - 1);
    console.log(count);

    if (count === 0) {
      setShowPrevButton(false);
      setShowNextButton(true);
      return;
    }

    if (epub_rendition.location.start.index == 4) {
      console.log("clicked on prevPage 4");
      setShowStartButton(false);
      setShowPrevButton(false);
      await epub_rendition.display(0);
    } else {
      await epub_rendition.prev();
      setShowFinishButton(false);
    }

    // Check if the current page index is the first index
    if (epub_rendition.location.atStart) {
      setShowPrevButton(false);
      setShowNextButton(true);
    } else {
      // setShowPrevButton(true);
      setShowNextButton(true);
    }

    // Check if the current page index is the first index
    if (epub_rendition.location.atStart) {
      setShowPrevButton(false);
      setShowNextButton(true);
    } else {
      // setShowPrevButton(true);
      setShowNextButton(true);
    }

    var highlightText = document.getElementById("textHighlight");
    if (highlightText) {
      highlightText.innerHTML = "?";
      highlightText.style.display = "none";
    }
  };

  function getRandomID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  const getShownText = () => {
    console.log("get shown func");
    var range = epub_rendition.getRange(
      epub_rendition.currentLocation().start.cfi
    );
    var endRange = epub_rendition.getRange(
      epub_rendition.currentLocation().end.cfi
    );
    console.log(range);
    console.log(endRange);
    range.setEnd(endRange.startContainer, endRange.startOffset);
    let targetPhrase = range.toString();
    setTargetPhrase(targetPhrase);
    console.log(targetPhrase);
  };

  function getChildGrade() {
    let childData = LocalStorage.fetch.childData();
    return childData?.list?.[0]?.grade?.name;
  }
  const stopRecording = async () => {
    console.log("reached function stop rec");
    var mic_listening = document.getElementById("mic_listening");
    mic_listening.style.display = "none";
    setShowStartButton(true);
    setStopRecButton(false);
    setShowLoading(true);
    setShowStartButton(false);
    setShowPrevButton(false);
    setShowNextButton(false);
    try {
      console.log("try");
      const audio = await recorder.stop();
      console.log("audio:", audio);

      var wavfromblob = new File([audio.audioBlob], "incomingaudioclip.wav");
      var audioFormdata = new FormData();
      audioFormdata.append("file", wavfromblob, "New Recording.wav");

      const response = await fetch(
        "https://api.getfreadom.com/api/misc/v1/media-v2/",
        {
          method: "post",
          body: audioFormdata,
        }
      );

      if (!response.ok) {
        // Handle HTTP errors (e.g., 4xx, 5xx)
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const text = await response.json();
      var token = LocalStorage.fetch.authToken();
      console.log("Token", token);

      const fluencyResponse = await fetch(
        "https://api.getfreadom.com/api/misc/v1/reading-fluency-iitb/",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            // authorization: `JWT ${token}`,
          },
          body: JSON.stringify({
            audio_path: text?.result?.file,
            book_title: storyData.name,
            process_id: process_id,
            session_id: session_id,
            grade: "Test POC",
            page: epub_rendition.location.start.index - 3,
          }),
        }
      );

      if (!fluencyResponse.ok) {
        // Handle API errors
        const fluencyError = await fluencyResponse.json();
        const errorMessage =
          fluencyError?.ExceptionMessage ||
          "Internal Server Error. Contact Administrator.";

        // Update loader and button states
        setShowLoading(false);
        setStopRecButton(false);
        setShowStartButton(true);
        setShowNextButton(true);
        setShowPrevButton(true);

        // Display error message to the user with styling
        const apiResponseElement = document.getElementById("api-response-text");
        const startButton = document.getElementById("recordButton");

        if (apiResponseElement && startButton) {
          apiResponseElement.innerHTML = `<span style="font-weight: 600; font-size: 20px; text-align: center; color: red;margin-bottom:2px; width:100%;" className="alert-class">Please read again!</span>`;
          startButton.innerHTML = "Start";
        } else {
          console.error("api-response-text or recordButton element not found");
        }
      } else {
        console.log("else");
        let resp = await fluencyResponse.json();
        const allPTags = document.querySelectorAll("p");
        // allPTags.style.marginLeft="10%";
        // allPTags.style.marginRight="10%";
        var iframe = document.querySelector("iframe");
        var targetHtml = " ";
        var ePubTextList = iframe.contentWindow.document.querySelectorAll("p");
        console.log("epublist", ePubTextList);
        var imagecontainer =
          iframe.contentWindow.document.querySelectorAll("img");
        console.log("imagecontainer", imagecontainer);
        if(imagecontainer.length>0){
          imagecontainer[0].style.width = "200px";
          imagecontainer[0].style.height = "200px";
        }
        ePubTextList.forEach(function (p) {
          targetHtml += p.outerHTML;
          p.remove();
        });
        var ifrDoc = iframe.contentDocument;

        var elem = ifrDoc.createElement("div");

        elem.innerHTML = resp?.result?.marked_text;
        ifrDoc.body.appendChild(elem);

        setShowLoading(false);
        setShowStartButton(true);
      }
    } catch (error) {
      console.error("Error in stopRecording:", error);

      // Update loader and button states
      setShowLoading(false);
      setStopRecButton(false);
      setShowStartButton(true);

      // Display generic error message to the user with styling
      const apiResponseElement = document.getElementById("api-response-text");
      const startButton = document.getElementById("recordButton");

      if (apiResponseElement && startButton) {
        apiResponseElement.innerHTML = `<span style="font-weight: 600; font-size: 20px; text-align: center;color: red; margin-bottom:2px;">Error in processing audio. Please try again.</span>`;
        startButton.innerHTML = "Start";
      } else {
        console.error("api-response-text or recordButton element not found");
      }
    }

    setShowNextButton(true);
    setShowPrevButton(true);
  };

  const startRecording = async () => {
    console.log("start func");
    const apiResponseElement = document.getElementById("api-response-text");
    console.log("API div okay!", apiResponseElement);
    apiResponseElement.innerHTML = "";

    setApiResponse(null);
    setShowStartButton(false);
    setShowTryAgainButton(false);

    var session_id = getRandomID();
    setSessionId(session_id);
    console.log(session_id);
    var process_id = getRandomID();
    setProcessId(process_id);
    console.log(process_id);
    var mic_listening = document.getElementById("mic_listening");

    console.log("Mic", mic_listening);
    setShowNextButton(false);
    setShowPrevButton(false);

    // tryAgainButton.style.display = "none";
    setShowTryAgainButton(false);
    setShowErrorMessage(false);
    // errorDiv.style.display = "none";
    setShowErrorDiv(false);
    // highlightText.innerHTML = "?";
    // highlightText.style.display = "none";
    setShowTextHighlight(false);
    // stopButton.disabled = false;
    setStopRecButton(true);
    getShownText();
    mic_listening.style.display = "block";
    var recorder = await recordAudio();
    setRecorder(recorder);
    console.log("Recorder", recorder);
    recorder.start();
    var options = {};
  };

  const nextPage = async () => {
    console.log("reached next page");
    if (epub_rendition?.location?.atEnd) {
      console.log("Already at the last page");
      setShowFinishButton(true);
      setShowNextButton(false);
      fin = count;
      // replace the current url with /read-along

      return;
    } else {
      setShowFinishButton(false);
    }
    if (fin == count) {
      setShowFinishButton(false);
    }
    setcount(count + 1);
    console.log(count);

    if (count >= 0) {
      setShowPrevButton(true);
    }

    // Check if the current location is already at the end

    if (epub_rendition.location.start.index < 4) {
      console.log("in thumbnail");
      setShowStartButton(true);
      await epub_rendition.display(4);
    } else {
      setShowStartButton(true);
    //   let nextDone = new Promise(async (res, reject) => {
    //     res(await epub_rendition.next());
    //   });

    //   console.log("nextDone", nextDone);
    //   nextDone.then(async (res) => {
    //     console.log("index:", epub_rendition?.location?.start?.index);
    //     console.log("prev", epub_rendition);
    // console.log("index 407:", epub_rendition?.location?.atEnd);

    //     // await LoadAudio();
    //     updateRendition(epub_rendition);
    //     let current_location = epub_rendition.currentLocation();
    //     // console.log("new", epub_rendition, current_location);
    //      if (current_location?.atEnd) {
    //        console.log("Already at the last page");
    //        setShowFinishButton(true);
    //        setShowNextButton(false);
    //        // window.location = "/read-aloud/";
    //      } else {
    //        setShowFinishButton(false);
    //      }
      await epub_rendition.next();
      // console.log("for others", epub_rendition?.location,current_location);
    // })
  }

    // Check if the current page index is the last index
    if (epub_rendition.location.atEnd) {
      console.log("reached end");
      setShowNextButton(false);
      setShowPrevButton(true);
    } else {
      console.log("mid");
      setShowNextButton(true);
      setShowPrevButton(true);
    }
  };

  const FinishStory = async () => {
    window.location = "/read-along";
  };

  return (
    <div>
      <Navbar />
      <div className="read_along_container">
        {showPrevButton ? (
          <div className="left-icon" id="prev" onClick={prevPage}>
            <FaChevronLeft className="nav-icon leftnav pdf_control_btn" />
          </div>
        ) : (
          <div className="left-icon" />
        )}
        {/* {console.log("state", showPrevButton)} */}

        <div className="modal-content">
          <div
            style={{
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              backgroundColor: "#F1EEF5",
              position: "relative",
            }}
          ></div>

          <div id="epub-file" className="epubfile"></div>
        </div>
        {showNextButton ? (
          <div className="right-icon" id="next" onClick={nextPage}>
            <FaChevronRight className="nav-icon rightnav pdf_control_btn" />
          </div>
        ) : (
          <div className="right-icon" />
        )}
      </div>

      {showErrorDiv ? (
        <div className="errorDiv">
          <p id="error-message"></p>
          <p id="highlight-text"></p>
        </div>
      ) : null}
      <div>
        <div id="error-message" className="col-md-12">
          {showErrorMessage ? (
            <h2>
              Error: <span id="error-message-full">?</span>
            </h2>
          ) : null}
        </div>
      </div>
      <div>
        <div
          id="api-response-text"
          dangerouslySetInnerHTML={{ __html: apiResponse }}
          className="col-md-12"
        >
          {/* {apiResponse} */}
        </div>
      </div>

      <div
        className="row text-center"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div
          style={{
            width: "100%",
            marginBottom: "0px",
            marginTop: "-10px",
            display: "none",
            verticalAlign: "baseline",
          }}
          id="mic_listening"
        >
          <img
            style={{ height: "3rem", marginTop: "4px", marginBottom: "4px" }}
            className="img-recorder"
            src={micListeningGif}
            alt="Mic Listening"
          />
          <span style={{ fontSize: "15px" }}>Recording now</span>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {showLoading ? (
          <img
            style={{ height: "5rem", justifyContent: "center" }}
            className="loading-symbol"
            src={Loader}
            alt="loading"
            id="load"
          />
        ) : null}
      </div>
      {showTextHighlight ? (
        <div
          id="textHighlight"
          // class="row text-center justify-content-center"
          className="row text-center justify-content-center"
          // style="margin-top: 20px; display: none"
          style={{ marginTop: "20px", display: "none" }}
        >
          ?
        </div>
      ) : null}
      {/* <p className="alert-class" id="api-response-text" style={{color:'red', fontWeight:'600'}}></p> */}
      <div className="modal-buttons">
        {showStartButton && (
          <button
            className="record-button"
            onClick={startRecording}
            id="recordButton"
          >
            Start
          </button>
        )}
        {showFinishButton && (
          <button
            className="record-button"
            onClick={FinishStory}
            id="recordButton"
          >
            Exit Story
          </button>
        )}

        {showTryAgainButton && (
          <button className="try-again-button" id="try-again-button">
            Try Again
          </button>
        )}
        {stopRecButton && (
          <button
            className="end-button"
            id="end-button"
            onClick={stopRecording}
          >
            Stop Recording
          </button>
        )}
      </div>
    </div>
  );
};

export default StoryDetailsPage;
