import React, { useState } from 'react'

import { Button, Form, Input, Select, TreeSelect, DatePicker, Checkbox } from "antd";
import moment from 'moment';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "store/user/actions";
import * as teacherActions from "store/teacher/actions";
import * as homeActions from "store/home/actions";
import * as storyActions from "store/story/actions";
import * as activityActions from "store/activities/actions";
import { storage as LocalStorage } from "services/config/storage";


import CloseWhiteIcon from "assets/image/close-white.svg";
import { FaRegCheckCircle } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import AssignSections2 from 'assets/image/assignSections2.png'
import AssignReaderType from 'assets/image/assignSections.svg'
import AssignStudents from 'assets/image/assignStudents.svg'
import './createAssignment.scss'
import { useEffect } from 'react';
import ToastComponent from '../../ToastComponent/ToastComponent';


const CreateAssignment = ({ onCloseModal, data, getAllClassMates, createAssignment, type, updateAssignments }) => {

    const { Option } = Select

    const [assigneeTypes, setAssigneeTypes] = useState([
        {
            label: "Assign to student(s)",
            value: "child",
            image: AssignStudents
        },
        {
            label: "Assign to section(s)",
            value: "section",
            image: AssignSections2
        },
    ])
    const [assignmentDetails, setAssignmentDetails] = useState({
        name: "",
        description: "",
        deadline: "",
        content: data ? data : null,
        assigneeType: null
    })

    const [page, setPage] = useState('first')
    const [allChildren, setAllChildren] = useState(null)
    const [allSections, setAllSections] = useState(null)
    const [allReaderTypeStudents, setAllReaderTypeStudents] = useState(null)
    const [studentsBasedOnReaderType, setStudentsBasedOnReaderType] = useState(null)
    const [error, setError] = useState(null)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
    })
    const [selectedAssignee, setSelectedAssignee] = useState([])

    useEffect(() => {
        const currentChild = LocalStorage.fetch.defaultChild();
        const gradeName = currentChild?.grade?.name || '';

        processTreeData(currentChild?.school_class, gradeName);
        fetachAllClassMates();
    }, [])

    const fetachAllClassMates = async () => {
        try {
            const result = await getAllClassMates();
            setAllStudentsBasedOnReaderType(result)
            await findSelectedSchoolClassChildren(result);
        } catch (err) {
            console.error('Error fetching children data: ', err);
        }
    }

    const findSelectedSchoolClassChildren = async (schoolClassData) => {
        const selectedSchoolClassChild = getSelectedSchoolClassChildren(schoolClassData);
        if (selectedSchoolClassChild.length > 0) {
            await fetchAllChildDetails(selectedSchoolClassChild);
            return selectedSchoolClassChild
        }
    }

    const getSelectedSchoolClassChildren = (schoolClassData) => {
        let allClassmates = [];
        schoolClassData.forEach(item => {
            allClassmates = allClassmates.concat(item.classmates);
        });

        return allClassmates;

    }

    const submitAssignmentDetails = () => {
        setPage("second")
    }

    const submitAssignType = () => {
        setPage("third")
    }

    const submitCreateAssignmentForm = (e) => {
        e.preventDefault();

        let content_type = assignmentDetails?.content?.content_type;
        if (content_type === "news_fread") {
            content_type = "news";
        }
        var payload = {
            name: assignmentDetails?.name,
            description: assignmentDetails?.description,
            deadline: assignmentDetails?.deadline,
            assignee: selectedAssignee,
            assignee_type: assignmentDetails?.assigneeType === 'reader-type' ? 'child' : assignmentDetails?.assigneeType,
            content_id: assignmentDetails?.content?.id,
            content_type: content_type.toLowerCase(),
        }

        createAssignment(payload).then((res) => {

            if (res && (res.status != 200 || res.status != 201) && !res.success) {
                setError("Something went wrong. Please try again.");
            } else {
                updateAssignments && updateAssignments()
                setShowToast({
                    show: true,
                    message: "Assignment created successfully."
                })
                setTimeout(() => {
                    setShowToast({
                        show: false,
                        message: ""
                    })
                    onCloseModal()
                }, 3000);
            }

        })

    }

    const processTreeData = (data, gradeName) => {
        // console.log("data", data);
        var result = [];
        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                // console.log(key, data[key]);
                let child = { "value": data[key].id, "label": gradeName + " - " + data[key].name, "children": [] };
                result.push(child);
            }
        }
        setAllSections(result)
        // console.log("result", result);
    }

    const processReaderTypeTreeData = (data) => {
        var result = [];
        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                console.log(key, data[key]);
                let child = { "value": data[key].child_id, "label": data[key].child__user__name, "children": [] };
                result.push(child);
            }
        }
        return result
        // console.log("result", result);
    }

    const treeformData = (data, all_children_data) => {
        if (data) {
            var school_class_name = data.school_class[0].name;
            var school_class_id = data.school_class[0].id;
            var found = false;
            // traverse the all_children_data object using for loop
            for (var key in all_children_data) {
                if (all_children_data[key].value == school_class_id) {
                    found = true;
                    all_children_data[key].children.push({ "label": data.name, "value": data.id, "children": [] });
                    break
                }
            }
            if (!found) {
                all_children_data.push({ "label": school_class_name, "value": school_class_id, "children": [{ "label": data.name, "value": data.id, "children": [] }] });
            }

        }

        // console.log("all_children_data:", all_children_data);
        return all_children_data;
    }

    const fetchAllChildDetails = async (selectedSchoolClassChildren) => {
        let childResponses = new Object();
        var all_children_data = [];
        selectedSchoolClassChildren && selectedSchoolClassChildren.length > 0 && selectedSchoolClassChildren.forEach(async (child) => {
            try {
                // const resp = await this.props.getChildDetails(childId);
                let resp = {
                    "id": child.child_id,
                    "name": child.child__user__name,
                    "school_class": [
                        {
                            "name": child.school_class__name,
                            "id": child.school_class_id
                        }
                    ]
                }
                childResponses[child.child_id] = resp;
                treeformData(resp, all_children_data);
            } catch (error) {
                console.error(`Error fetching child details for child id ${child.child_id}:`, error);
            }
        });

        setAllChildren(all_children_data)
    }

    const disabledDate = current => {
        return current && (!current.isSame(moment(), 'year') || current.isBefore(moment(), 'day'));
    };

    const handleBackButton = (type) => {
        setPage(type)
    }

    const setAllStudentsBasedOnReaderType = (classMatesData) => {
        let allClassmates = classMatesData?.flatMap((classmate) => classmate.classmates)
        console.log(allClassmates)
        const transformedClassmates = allClassmates?.reduce((acc, classmate) => {
            console.log(acc, "accumulator")
            // Check if the job already exists as a key in the accumulator
            if (!acc[classmate.reader_type]) {
                acc[classmate.reader_type] = []; // If not, create an empty array for the job
            }
            // Push the current user object into the array for that job
            acc[classmate.reader_type].push(classmate);
            return acc; // Return the accumulator for the next iteration
        }, {}); // Initialize the accumulator as an empty object

        if (transformedClassmates && transformedClassmates['null'].length !== allClassmates.length) {
            let assigneeTypesData = assigneeTypes
            assigneeTypesData.push({
                label: "Assign by level(s)",
                value: "reader-type",
                image: AssignReaderType
            })
            setAssigneeTypes(assigneeTypesData)
        }
        setStudentsBasedOnReaderType(transformedClassmates)

    }

    const getStudentsBasedOnReaderType = async (val) => {
        const filteredClassmates = studentsBasedOnReaderType[val]
        let readerTypeStudents = processReaderTypeTreeData(filteredClassmates)
        setAllReaderTypeStudents(readerTypeStudents)

    }

    return (
        <div className="create-assignment-container">
            <div className="create-description-card" style={{ overflowX: 'hidden' }}>

                <div className="flex">
                    <p className="total-activities-text">{page === 'first' ? `Assign ${type} as Assignment` : page === 'second' ? "Select the Assignee type" : "Select the Assignees"}</p>
                    <img
                        src={CloseWhiteIcon}
                        className="close-icon"
                        alt="close"
                        onClick={onCloseModal}
                    />
                </div>

                <div className="description-container">

                    {/* create a form with name and description  */}
                    {page === 'first' ?
                        <form className='form_container' onSubmit={submitAssignmentDetails}>
                            <Form.Item
                                validateStatus={error ? "error" : "success"}
                                help={error}
                                className="form_item"
                            >
                                <Input
                                    placeholder="Name of the assignment"
                                    value={assignmentDetails?.name}
                                    onChange={(e) => {
                                        const { value } = e.target
                                        setAssignmentDetails((prev) => ({ ...prev, name: value }));
                                    }}
                                    className="form_item_input"
                                    style={{ width: '100%', height: '50px' }} // Adjust width and height as needed
                                />
                            </Form.Item>


                            <Form.Item
                                validateStatus={error ? "error" : "success"}
                                help={error}
                                className="form_item"

                            >
                                <Input.TextArea
                                    placeholder="Description of the assignment"
                                    value={assignmentDetails?.description}
                                    onChange={(e) => {
                                        const { value } = e.target
                                        setAssignmentDetails((prev) => ({ ...prev, description: value }))
                                    }}
                                    className="form_item_input"

                                    autoSize={{ minRows: 2, maxRows: 6 }} // This will allow the textarea to expand vertically
                                    style={{ width: '100%', lineHeight: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '22px 12px !important' }} // Adjust width as needed
                                />
                            </Form.Item>

                            <Form.Item
                                validateStatus={error ? "error" : "success"}
                                help={error}
                                className="form_item"

                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select date!',
                                    },
                                ]}
                            >
                                <DatePicker
                                    placeholder="Deadline"
                                    value={assignmentDetails?.deadline ? moment(assignmentDetails?.deadline) : null}
                                    onChange={(date, dateString) => {
                                        setAssignmentDetails((prev) => ({ ...prev, deadline: dateString }))
                                    }}
                                    className="form_item_input custom-datepicker"

                                    disabledDate={disabledDate}
                                />
                            </Form.Item>

                            <Form.Item
                                validateStatus={error ? "error" : "success"}
                                help={error}
                                className="form_item"
                            >
                                <Input
                                    placeholder="Name of the assignment"
                                    value={assignmentDetails?.content?.name}
                                    className="form_item_input"
                                    disabled={true}
                                    style={{ width: '100%', height: '50px' }} // Adjust width and height as needed
                                />
                            </Form.Item>

                            <Button
                                type="primary"
                                htmlType="submit"
                                className="form_submit_button"
                                disabled={assignmentDetails?.name.trim() === "" || assignmentDetails.description.trim() === "" || assignmentDetails.deadline === ''}
                            >
                                Next
                            </Button>

                        </form>
                        : null}

                    {page === 'second' ?
                        <form className='form_container page_second' onSubmit={submitAssignType}>

                            <div className='assignee_type_container' style={{ gridTemplateColumns: window.innerWidth>520 ?`repeat(${assigneeTypes.length},1fr)` :''}}>
                                {
                                    assigneeTypes.map((asignee) => (
                                        <div key={asignee.value} className={`assignee_type_card_container ${assignmentDetails?.assigneeType === asignee.value ? 'active' : ''}`} onClick={() => { setSelectedAssignee([]); setAssignmentDetails((prev) => ({ ...prev, assigneeType: asignee.value })) }}>
                                            <img src={asignee?.image} alt="image" />
                                            <p>{asignee?.label}</p>
                                            {assignmentDetails?.assigneeType === asignee.value ? <FaCheckCircle className='assignee_check_icon_circle' /> : <FaRegCheckCircle className='assignee_check_icon' />}
                                        </div>
                                    ))
                                }

                            </div>
                            <div className='form_btns_container'>
                                <Button
                                    type="primary"
                                    className="form_submit_button"
                                    onClick={() => handleBackButton('first')}
                                >
                                    Back
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="form_submit_button"
                                    disabled={assignmentDetails?.assigneeType === null}
                                >
                                    Next
                                </Button>

                            </div>
                        </form>

                        : null}
                    {page === 'third' ?
                        <form className='form_container' onSubmit={submitCreateAssignmentForm}>

                            {
                                assignmentDetails?.assigneeType === 'reader-type' &&
                                <div className='reader_type_select'>
                                    <Form.Item
                                        // validateStatus={error ? "error" : "success"}
                                        // help={error}
                                        className="form_item"
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="Students"
                                            dropdownClassName="reader_type_drop-down"
                                            notFoundContent="No students found."
                                            style={{
                                                width: '100%',
                                            }}
                                            onChange={(value) => getStudentsBasedOnReaderType(value)}
                                            placeholder={<span style={{ color: 'grey' }}>Select Reader Category</span>}
                                        >
                                            <Option className='reader_type_option' key="struggling_reader" value="struggling-reader">
                                                Struggling Reader
                                            </Option>
                                            <Option className='reader_type_option' key="average_reader" value="average-reader">
                                                Average Reader
                                            </Option>
                                            <Option className='reader_type_option' key="excellent_reader" value="excellent-reader">
                                                Excellent Reader
                                            </Option>
                                        </Select>
                                    </Form.Item>

                                </div>

                            }

                            <div className="select_assignee_container" style={{ width: "100%" }}>
                                <div className="select_assignee_item">
                                    <Form.Item
                                        validateStatus={error ? "error" : "success"}
                                        help={error}
                                        className="form_item"
                                    >
                                        <TreeSelect
                                            showSearch
                                            mode="multiple"
                                            style={{
                                                width: '100%',
                                            }}
                                            dropdownClassName="selectassigneedropdown"
                                            dropdownStyle={{ maxHeight: 230, width: 170, overflow: 'auto', zIndex: "100000", left: 660 }}
                                            placeholder={<span style={{ color: 'grey' }}> {assignmentDetails?.assigneeType === "child" ? "Please select students" : assignmentDetails?.assigneeType === 'section' ? "Please select sections" : "Please select students"} </span>}
                                            allowClear
                                            treeDefaultExpandAll={true}
                                            treeCheckable={true}
                                            value={selectedAssignee}
                                            onChange={(value) => setSelectedAssignee(value)}
                                            treeData={assignmentDetails?.assigneeType === "child" ? allChildren : assignmentDetails?.assigneeType === 'section' ? allSections : allReaderTypeStudents}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='form_btns_container'>
                                <Button
                                    type="primary"
                                    // htmlType="submit"
                                    className="form_submit_button"
                                    onClick={() => handleBackButton('second')}
                                // disabled={this.state.enteredName.trim() === "" || this.state.enteredDescription.trim() === ""}
                                >
                                    Back
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="form_submit_button"
                                    onClick={(e) => submitCreateAssignmentForm(e)}
                                    disabled={selectedAssignee && selectedAssignee.length === 0}

                                >
                                    Create
                                </Button>

                            </div>
                        </form>
                        : null}

                </div>
            </div>
            {
                showToast?.show && <ToastComponent message={showToast?.message} />
            }
        </div>
    )
}

const connectedComponent = connect(
    ({ user, teacher, story, activity, appConfig, home }) => ({
        ...user,
        ...appConfig,
        ...teacher,
        ...story,
        ...activity,
        ...home,
    }),
    (dispatch) => bindActionCreators({
        ...userActions,
        ...teacherActions,
        ...homeActions,
        ...storyActions,
        ...activityActions,
    }, dispatch)
)(CreateAssignment);


export default Form.create()(connectedComponent);